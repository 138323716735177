<template>
  <div>
    <b-row>
      <b-col
        cols="6"
      >
        <h6>Nama</h6>
      </b-col>
      <b-col
        cols="6"
      >
        <p>{{ paket.name ? paket.name : '-' }}</p>
      </b-col>
      <b-col
        cols="6"
      >
        <h6>Harga</h6>
      </b-col>
      <b-col
        cols="6"
      >
        <p>{{ paket.price | formatAmount }}</p>
      </b-col>
      <b-col
        cols="6"
      >
        <h6>Deskripsi</h6>
      </b-col>
      <b-col
        cols="6"
      >
        <p>{{ paket.description ? paket.description : '-' }}</p>
      </b-col>
      <b-col
        cols="6"
      >
        <h6>Jam Kerja</h6>
      </b-col>
      <b-col
        cols="6"
      >
        <p>{{ paket.working_time ? paket.working_time : '-' }}</p>
      </b-col>
      <b-col
        cols="6"
      >
        <h6>Meeting</h6>
      </b-col>
      <b-col
        cols="6"
      >
        <p>{{ paket.meet_count ? paket.meet_count : '-' }}</p>
      </b-col>
    </b-row>
    <div
      class="table-responsive"
      :class="{ 'd-none' : unit == 'meet'}"
    >
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Nama</th>
            <th>Jam</th>
            <th>Harga</th>
            <th>Meeting</th>
            <th>Tipe Meeting</th>
            <th>Deskripsi</th>
            <th>Alamat</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="step in paket.steps"
            :key="step.id"
          >
            <td>
              {{ step.name ? step.name : '-' }}
            </td>
            <td>
              {{ step.time ? step.time : '-' }}
            </td>
            <td>
              {{ step.price | formatAmount }}
            </td>
            <td>
              {{ step.need_meet ? step.need_meet : '-' }}
            </td>
            <td>
              {{ step.meet_type ? step.meet_type : '-' }}
            </td>
            <td>
              {{ step.description ? step.description : '-' }}
            </td>
            <td>
              {{ step.location ? step.location : '-' }}<br>
              <a
                v-if="step.location"
                :href="`https://maps.google.com/?q=${step.location_latitude},${step.location_longitude}`"
                class="font-weight-bold"
                target="_blank"
              >
                Buka Google Maps
                <feather-icon
                  icon="MapPinIcon"
                  class="ml-1"
                />
              </a>
            </td>
            <!-- <td v-if="step.location_set == 'now'">
              <l-map
                :zoom="zoom"
                :center="[parseInt(step.location_latitude), parseInt(step.location_longitude)]"
              >
                <l-tile-layer :url="url" />
                <l-circle
                  :lat-lng="[parseInt(step.location_latitude), parseInt(step.location_longitude)]"
                  :radius="4500"
                  :color="circle.color"
                />
              </l-map>
            </td> -->
          </tr>
          <tr v-if="paket.steps.length == 0">
            <td
              colspan="7"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
// import { LMap, LTileLayer, LCircle } from 'vue2-leaflet'
// import 'leaflet/dist/leaflet.css'

export default {
  components: {
    BRow,
    BCol,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    paket: {
      type: Object,
    },
    unit: {
      type: String,
    },
  },
}
</script>
