<template>
  <div v-if="jasaData">
    <b-card>
      <b-tabs content-class="pt-1">
        <b-tab
          title="Info Jasa"
        >
          <InfoJasa :jasa-data="jasaData" />
        </b-tab>
        <b-tab
          title="Info Talenta"
        >
          <InfoTalent :jasa-data="jasaData" />
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <b-tabs content-class="pt-1">
        <b-tab
          v-for="paket in jasaData.packages"
          :key="paket.uuid"
          :title="paket.name"
        >
          <InfoPaket
            :paket="paket"
            :unit="jasaData.unit"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import {
  checkPermission,
  successNotification,
  errorNotification,
} from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import InfoTalent from '@/components/Jasa/InfoTalent.vue'
import InfoJasa from '@/components/Jasa/InfoJasa.vue'
import InfoPaket from '@/components/Jasa/InfoPaket.vue'

Vue.use(VuejsDialog)
export default {
  title() {
    return 'Detail Jasa'
  },
  components: {
    BCard,
    BTabs,
    BTab,
    InfoTalent,
    InfoJasa,
    InfoPaket,
  },
  data() {
    return {
      id: this.$route.params.id,
      jasaData: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [47.313220, -1.319482],
      circle: {
        center: [47.413220, -1.0482],
        radius: 4500,
        color: '#EA5455',
      },
    }
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$http.get(`/admin/services/${this.id}`).then(response => {
        this.jasaData = response.data.data
      })
    },
  },
}
</script>

<style lang="scss">
// .vue2leaflet-map{
//   &.leaflet-container{
//     height: 350px;
//   }
// }
</style>
