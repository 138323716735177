<template>
  <div>
    <h4>Info Jasa</h4>
    <h6>Foto Produk</h6>
    <div class="d-flex mb-1">
      <b-img
        v-for="(image, index) in jasaData.images"
        :key="index"
        class="produk-img mr-1"
        rounded
        :src="image.file_url"
      />
    </div>
    <table class="mt-2 mt-xl-0 w-100">
      <tr>
        <th>
          <span class="font-weight-bold">Nama Jasa</span>
        </th>
        <td>
          {{ jasaData.name ? jasaData.name : '-' }}
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Iklan</span>
        </th>
        <td>
          <span :class="jasaData.ads == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="jasaData.ads == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Status Aktif</span>
        </th>
        <td>
          <span :class="jasaData.is_active == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="jasaData.is_active == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Kategori</span>
        </th>
        <td>
          {{ jasaData.category.name ? jasaData.category.name : "-" }}
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Unit</span>
        </th>
        <td>
          {{ jasaData.unit ? jasaData.unit == 'project' ? 'Proyek' : 'Pertemuan' : "-" }}
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Peralatan Kerja</span>
        </th>
        <td>
          {{ jasaData.working_tools ? jasaData.working_tools == 'talent' ? 'Talenta' : 'Pembeli' : "-" }}
        </td>
      </tr>
      <tr>
        <th>
          <span class="font-weight-bold">Kebutuhan Alat</span>
        </th>
        <td>
          {{ jasaData.tools_requirement ? jasaData.tools_requirement : "-" }}
        </td>
      </tr>
      <tr v-if="jasaData.unit == 'meet'">
        <th>
          <span class="font-weight-bold">Jasa Interaksi</span>
        </th>
        <td>
          {{ jasaData.service_interaction ? jasaData.service_interaction : "-" }}
        </td>
      </tr>
      <tr v-if="jasaData.unit == 'meet'">
        <th>
          <span class="font-weight-bold">Lokasi Jasa Interaksi</span>
        </th>
        <td>
          {{ jasaData.service_interaction_offline_location ? jasaData.service_interaction_offline_location : "-" }}
        </td>
      </tr>
      <tr v-if="jasaData.is_insurance == true">
        <th>
          <span class="font-weight-bold">Asuransi ditanggung Talenta</span>
        </th>
        <td>
          {{ jasaData.insurance_by_talent ? jasaData.insurance_by_talent+'%' : "-" }}
        </td>
      </tr>
      <tr v-if="jasaData.is_insurance == true">
        <th>
          <span class="font-weight-bold">Asuransi ditanggung Pembeli</span>
        </th>
        <td>
          {{ jasaData.insurance_by_buyer ? jasaData.insurance_by_buyer+'%' : "-" }}
        </td>
      </tr>
    </table>
    <h6 class="mt-2">
      Deskripsi
    </h6>
    <p>
      {{ jasaData.description ? jasaData.description : "-" }}
    </p>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    jasaData: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  tr {
    th {
      width: 50%;
    }
  }
}
.produk-img {
  max-width: 100px;
  @media (max-width: 576px) {
    max-width: 60px;
  }
  @media (max-width: 320px) {
    max-width: 40px;
  }
}
</style>
