<template>
  <div>
    <!-- User Avatar & Action Buttons -->
    <h4>Info Talenta</h4>
    <div class="d-lg-flex d-xs-block justify-content-start">
      <b-avatar
        :src="jasaData.talent.image"
        :text="jasaData.talent.name != null ? jasaData.talent.name.charAt(0) : '-'"
        :variant="`light-danger`"
        size="104px"
        rounded
      />
      <div class="d-flex flex-column ml-lg-1 mt-lg-0 mt-1 w-100">
        <div class="mb-0 mb-lg-1">
          <h4>
            {{ jasaData.talent.name != null ? jasaData.talent.name : '-' }}
          </h4>
          <span class="card-text badge badge-light-primary text-capitalize">Status: {{ jasaData.talent.status != null ? jasaData.talent.status : '-' }}</span>
        </div>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <span class="font-weight-bold">Kota</span>
            </th>
            <td>
              {{ jasaData.talent.city.name }}
            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Status Aktif</span>
            </th>
            <td>
              <span :class="jasaData.talent.status == true ? 'text-success' : 'text-danger'">
                <feather-icon
                  :icon="jasaData.talent.status == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                  class="mr-75"
                />
              </span>
            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Badan Hukum</span>
            </th>
            <td>
              <span :class="jasaData.talent.is_corporate == true ? 'text-success' : 'text-danger'">
                <feather-icon
                  :icon="jasaData.talent.is_corporate == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                  class="mr-75"
                />
              </span>
            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Terdaftar pada</span>
            </th>
            <td>
              {{ jasaData.talent.register_at != null ? jasaData.talent.register_at : "-" }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    jasaData: {
      type: Object,
    },
  },
}
</script>
